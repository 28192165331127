import React, { useState, useEffect } from "react";
import styles from "../../../assets/styles/orgSettings.module.scss";
import closeIcon from "../../../assets/images/close-square.svg";
import { Grid, LinearProgress } from "@material-ui/core";
import { imageBasePath } from "../../../constants";
import {
  CustomButton,
  CustomLink,
  InputText,
  NoResult,
} from "../../../shared_ui_components";
import {
  createWallpaperApi,
  deleteWallpaper,
  confirmWallpaper,
} from "../apiServices";
import { getWorkspaceWallpaperApi } from "../../workspaces/apiServices";
import { toastFlashMessage } from "../../../utils";
import { useParams } from "react-router";
import { WallpaperImages } from "./WallpaperImages";
import Shimmer from "./Shimmer";
import PreviewIsNotAvailable from "./PreviewIsNotAvailable";

function CustomImage({ wallpaperLink, setImageLinkError }) {
  const link = new URL(`${wallpaperLink}`);
  const localLink = link.protocol === "http:";

  if (localLink) {
    return <PreviewIsNotAvailable wallpaperLink={wallpaperLink} />
  }

  return (
    <img
      className={styles.wallpapers}
      style={{ height: "100%" }}
      onError={() => setImageLinkError(true)}
      src={wallpaperLink}
      alt=""
      title="image"
    />
  );
}

function CustomVideo({ wallpaperLink, setVideoLinkError }) {
  const link = new URL(`${wallpaperLink}`);
  const localLink = link.protocol === "http:";

  if (localLink) {
    return <PreviewIsNotAvailable wallpaperLink={wallpaperLink} />
  }

  return (
    <video
      src={wallpaperLink}
      className={styles.wallpapers}
      style={{ height: "100%" }}
      alt=""
      autoPlay
      onError={() => setVideoLinkError(true)}
      muted
      loop
    />
  );
}




export default function DisplayConfig(props) {
  const { orgLists } = props;
  const { id } = useParams();

  // const [formD, setFormD] = useState({
  //   orgRefId: id && id,
  // });
  const [formData, setFormData] = useState({
    orgId: id,
    orgWallpaper: [],
    orgWallpapers: [],
    link: "",
  });

  const [newImgs, setNewImgs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [wallpaperLink, setWallpaperLink] = useState("");
  const [wallpaperName, setWallpaperName] = useState("");
  const [imageLinkError, setImageLinkError] = useState(false);
  const [videoLinkError, setVideoLinkError] = useState(false);
  const [wallpaperFiles, setWallpaperFiles] = useState([]);

  const [wallpaperType, setWallpaperType] = useState("image");
  const [showPopup, setShowPopup] = useState(false);
  const [type, setType] = useState("wallpaperLink");

  const [uploadPercentage, setUploadPercentage] = useState(0);

  const handleType = (e) => {
    setType(e.target.value);
    setWallpaperFiles([]);
    setWallpaperLink("");
    setWallpaperType("image");
  };

  const getWelcomeDisplay = (wallpapers) => {
    setFormData((form) => ({
      ...form,
      orgWallpaper: wallpapers,
    }));
  };
  // const isWallpaperTypeAppLink=formData?.orgWallpaper?.filter((item)=>item.wallpaperType!=="appLink")
  useEffect(() => {
    getWorkspaceWallpaperApi({ orgRefId: id }).then((response) => {
      if (response.status) {
        console.log(response.data);
        // if(response.data.wallpaperType!=="appLink"){
        getWelcomeDisplay(response.data.wallpapers);

        // }
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, [id]);
  const handleChange = (e) => {
    setError(false);
    
    const files = Array.from(e.target.files)

    for (let i = 0; i < files.length; i++) {
      let fileSizeInMB = (files[i].size / 1024 / 1024).toFixed(4); // MB
      

      if (fileSizeInMB >= 500) {
        setError(`File Size must be below 500Mb`);
        e.target.value = null;
      }else {
        const fileType = files[i].type;
       
        const mp4 = fileType === "video/mp4"
        if(mp4){
          setWallpaperType("video");
        }
        
        const png = fileType === "image/png"
        const jpg = fileType === "image/jpg"
        const jpeg = fileType === "image/jpeg"

        if(png || jpg ||  jpeg){
          setWallpaperType("image");
        }

        setWallpaperFiles(Array.from(e.target.files));
      }
    }
  };

  const handleSubmit = () => {
    setError(false);
    setFormData((form) => ({
      ...form,
      orgWallpapers: [],
    }));
    let files = wallpaperFiles;

    for (let i = 0; i < files.length; i++) {
      setFormData((form) => ({
        ...form,
        orgWallpaper: [...form.orgWallpaper, URL.createObjectURL(files[i])],
        orgWallpapers: [...form.orgWallpapers, files[i]],
      }));
      setNewImgs([files[i]]);
    }
  };

  useEffect(() => {
    if (newImgs.length > 0 && !error) {
      setLoading(true);
      handleImportWallpapers();
    }
  }, [newImgs]);


  useEffect(() => {
    if (uploadPercentage === 100) {
      setTimeout(() => {
        setWallpaperFiles([]);
        setWallpaperLink("");
        setWallpaperType("image");
        setType("wallpaperLink");
        setShowPopup(false);
        setUploadPercentage(0)
        setNewImgs([]);
      }, 2000);
    }
  }, [uploadPercentage]);

  const handleImportWallpapers = (wallpaperLink) => {
    let form_data = new FormData();
    form_data.append("orgId", formData.orgId);
    form_data.append("wallpaperName", wallpaperName);
    form_data.append("wallpaperType", wallpaperType);
    if (wallpaperLink) {
      form_data.append("link", wallpaperLink);
    } else {
      formData.orgWallpapers.forEach((element) => {
        const newFile = {
          file: element,
          name: element.name,
        };
        form_data.append(
          `orgWallpaper`,
          newFile.file,
          newFile.name,
        );
      });
    }
    const options = {}
    options.onUploadProgress = (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      setUploadPercentage(percent);
    };
    createWallpaperApi(form_data, options).then((response) => {
      if (response.status) {
        setWallpaperLink("");
        // if(response.data.wallpaperType!=="appLink"){

        getWelcomeDisplay(response.data.wallpapers);
        // }
        setWallpaperName("");
        setLoading(false);
        toastFlashMessage("Wallpaper images uploaded successfully", "success");
      } else if (!response.status) {
        toastFlashMessage(response.response.data.message, "error");
      } else {
        setLoading(false);
        toastFlashMessage(
          "Something went wrong while uploading wallpaper images",
          "error"
        );
      }
    });
  };

  const handleDeleteWallpaper = (wallpaper) => {
    const form = {
      orgRefId: id && id,
      wallpaperId: wallpaper,
    };
    deleteWallpaper(form).then((response) => {
      if (response.status) {
        getWelcomeDisplay(response.wallpapersList.wallpapers);
        toastFlashMessage("Wallpaper image deleted successfully", "success");
      } else {
        toastFlashMessage(
          "Something went wrong while deleteing wallpaper image",
          "error"
        );
      }
    });
  };

  const defaultWallpapers = (wallpaper) => {
    const getDefaultWallpaper = formData.orgWallpaper.find(
      (item) => item._id == wallpaper
    )._id;
    //console.log(wallpaper, "wallpaper");
    const form = {
      orgId: id,
      wallpaperId: getDefaultWallpaper,
    };
    confirmWallpaper(form).then((response) => {
      if (response.status) {
        props.handleUpdateForm(response.wallpaper);
        toastFlashMessage(
          "Selected image has been set as default wallpaper successfully",
          "success"
        );
      } else {
        toastFlashMessage(
          "Something went wrong while setting default wallpaper",
          "error"
        );
      }
    });
  };
  const options = [
    { value: "wallpaperLink", label: "Link" },
    { value: "wallpaperUpload", label: "Upload", helperText : "jpg, jpeg, png, mp4 and file size below 500Mb" },
  ];
  const isVideo = orgLists?.orgWallpaper?.includes(".mp4");

  const handleWallpaperType = (event) => {
    setImageLinkError(false);
    setVideoLinkError(false);
    setWallpaperLink("");
    setWallpaperType(event.target.value);
  };
  
  return (
    <div className={styles.setWallpaperWrapper}>
      <div className={styles.addWallpaperContentWrapper}>
        <div className={styles.addWallpaperFrameWrapper}>
          <div className={styles.addWallpaperFrameImage}>
            <img src={imageBasePath + "wallpaper_frame.png"} alt="" />
            {isVideo ? (
              <video
                src={
                  orgLists?.orgWallpaper ??
                  imageBasePath + "wallpaper_demo_1.png"
                }
                className={styles.frameUpdatedPicture}
                alt=""
                autoPlay
                muted
                loop
              />
            ) : (
              <img
                src={
                  orgLists?.orgWallpaper ??
                  imageBasePath + "wallpaper_demo_1.png"
                }
                className={styles.frameUpdatedPicture}
                alt=""
              />
            )}
          </div>
          <div className={styles.addWallpaperConfigWrapper}>
            <p className={styles.ConfigTitle + " label"}>
              Wallpaper Configuration
            </p>
            <p className={styles.ConfigSubTitle + " label"}>
              Upload wallpapers to set as default wallpaper
            </p>

            <div className={styles.importButtonWrapper}>
              <button
                style={{ width: "165px" }}
                className={styles.importButton + " outlinedBtn "}
                onClick={() => {
                  setShowPopup(true);

                  setWallpaperLink("");
                  setWallpaperFiles("");
                }}
              >
                Wallpaper Config
              </button>
              <div
                id="myModal"
                style={{ display: `${showPopup ? "flex" : ""}` }}
                className={styles.modal}
              >
                <div className={styles.modalContent}>
                  <img
                    className={styles.close}
                    onClick={() => {
                      setShowPopup(false);
                      setWallpaperFiles([]);
                      setWallpaperLink("");
                      setWallpaperType("image");
                      setType("wallpaperLink");
                    }}
                    src={closeIcon}
                  />
                  <p
                    style={{ fontSize: "18px", marginBottom: "20px" }}
                    className={styles.ConfigTitle + " label"}
                  >
                    Wallpaper Name
                    <span style={{ fontSize: "14PX" }}>(optional)</span>
                  </p>
                  <InputText
                    className={styles.ConfigTitle + " label"}
                    placeholder="eg: xyz"
                    value={wallpaperName ? wallpaperName : ""}
                    onChange={(e) => {
                      setWallpaperName(e.target.value);
                    }}
                  />
                  {/*  */}
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <div className={styles.importButtonWrapper}>
                      <p
                        style={{ fontSize: "18px", marginBottom: "10px" }}
                        className={styles.ConfigTitle + " label"}
                      >
                        Wallpaper Type
                      </p>

                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        {options.map((option) => (
                          <label
                            key={option.value}
                            style={{
                              display: "flex",
                              gap: "5px",
                              fontSize: "14px",
                              color: "black",
                            }}
                          >
                            <input
                              type="radio"
                              value={option.value}
                              checked={type === option.value}
                              onChange={handleType}
                            />
                            {option.label}
                            {option.helperText ? <p style={{fontSize : "12px", color : "#4e606e", fontWeight : '400'}}>{`(${option.helperText})`}</p> : null}
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>

                  {type === "wallpaperUpload" && (
                    <>
                      <div style={{ marginBottom: "20px", position :"relative",  }}>
                        <CustomLink
                          className={styles.importButton + " outlinedBtn "}
                          text="Upload"
                        />
                        <input
                          type="file"
                          multiple
                          disabled={uploadPercentage ? true : false}
                          accept="image/png, image/jpg, image/jpeg, video/mp4"
                          onChange={handleChange}
                          style={{border : "1px solid black", opacity : "0", left : 0,position: "absolute", height : "100%", width : "100px",cursor : "pointer" }}
                        />
                      </div>
                      {uploadPercentage ? (
                        <div className="slidecontainer">
                          <div style={{ width: "200px" }}>
                            <LinearProgress variant="determinate" value={uploadPercentage} />
                          </div>
                          <div
                            style={{ fontSize: "12px", marginTop: "2px", marginBottom: "10px" }}
                          >
                            {uploadPercentage !== 100
                              ? "Uploading " + uploadPercentage + "%"
                              : "Finishing..."}
                          </div>
                        </div>
                      ) : null}
                      
                    </>
                  )}
                  {type === "wallpaperLink" && (
                    <div>
                      <RadioButtonGroup
                        wallpaperType={wallpaperType}
                        handleWallpaperType={handleWallpaperType}
                      />
                      <p
                        style={{ fontSize: "18px", marginBottom: "20px" }}
                        className={styles.ConfigTitle + " label"}
                      >
                        Enter wallpaper Link
                      </p>
                      <InputText
                        className={styles.ConfigTitle + " label"}
                        placeholder="eg, http://example.com"
                        value={wallpaperLink ? wallpaperLink : ""}
                        onChange={(e) => {
                          setWallpaperLink(e.target.value);
                          if (imageLinkError) {
                            setImageLinkError(false);
                          }
                          if (videoLinkError) {
                            setVideoLinkError(false);
                          }
                        }}
                      />

                      {imageLinkError && <p>Invalid Image Link</p>}
                      {videoLinkError && <p>Invalid Video Link</p>}
                      {wallpaperLink &&
                      !imageLinkError &&
                      wallpaperType === "image" ? (
                        <div className={styles.wallpapersList}>
                          {/* <iframe src={wallpaperLink}></iframe> */}
                          <CustomImage wallpaperLink={wallpaperLink} setImageLinkError={setImageLinkError}/>
                        </div>
                      ) : null}
                      {wallpaperLink &&
                      !videoLinkError &&
                      wallpaperType === "video" ? (
                        <div className={styles.wallpapersList}>
                          <CustomVideo wallpaperLink={wallpaperLink} setVideoLinkError={setVideoLinkError}/>
                        </div>
                      ) : null}
                    </div>
                  )}
                  {uploadPercentage === 0 && wallpaperFiles.length !== 0 &&
                    wallpaperFiles.map((file, index) => (
                      <div
                        className={styles.wallpapersList}
                        style={{ marginBottom: "50px" }}
                      >
                        {file.type.startsWith("image/") ? (
                          <img
                            className={styles.wallpapers}
                            style={{ height: "100%" }}
                            src={URL.createObjectURL(file)}
                            alt=""
                          />
                        ) : (
                          <video
                            src={URL.createObjectURL(file)}
                            className={styles.wallpapers}
                            style={{ height: "100%" }}
                            alt=""
                            autoPlay
                            muted
                            loop
                          />
                        )}
                      </div>
                    ))}
                  {error ? (
                    <div className="errorMsg" style={{ marginTop: "2px" }}>
                      {error}
                    </div>
                  ) : null}
                  <div
                    className={styles.addDomainBtnWrapper}
                    style={{ marginTop: `${wallpaperLink ? "60px" : ""}` }}
                  >
                    <CustomButton
                      children={"save wallpaper"}
                      className={`${styles.addDomainBtn} primaryBtn ${
                        typeof error === "string" ||
                        (type === "wallpaperLink" &&
                          (imageLinkError ||
                            videoLinkError ||
                            !wallpaperLink)) ||
                        (type === "wallpaperUpload" &&
                          wallpaperFiles.length === 0)
                          ? "disabledBtn"
                          : null
                      }`}
                      disabled={
                        typeof error === "string" ||
                        (type === "wallpaperLink" &&
                          (imageLinkError ||
                            videoLinkError ||
                            !wallpaperLink)) ||
                        (type === "wallpaperUpload" &&
                        uploadPercentage !== 0)
                      }
                      onClick={() => {
                        if (type === "wallpaperLink") {
                          handleImportWallpapers(wallpaperLink);
                        }
                        if (wallpaperFiles) {
                          handleSubmit();
                        }
                        
                      }}
                      type="button"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Shimmer type="wallpaperListLength" />
        ) : (
          <p className={styles.wallpaperCounts + " label"}>
            Wallpapers (<span>{formData?.orgWallpaper.length}</span>)
          </p>
        )}
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          {loading ? (
            <Shimmer type="wallpaperLists" />
          ) : formData?.orgWallpaper?.length === 0? (
            <div style={{ marginTop: "-100px" }}>
              <NoResult
                image="no_workspace_room.png"
                title="No Wallpapers found"
                subtext="Looks like you have no wallpapers. Start importing wallpapers"
              />
            </div>
          ) : (
            formData?.orgWallpaper?.map((item, key) => (
              <Grid item md={4}>
                <WallpaperImages
                  {...item}
                  key={key}
                  handleDeleteWallpaper={handleDeleteWallpaper}
                  defaultWallpapers={defaultWallpapers}
                  wallpaper={orgLists?.orgWallpaper}
                />
              </Grid>
            ))
          )}
        </Grid>
      </div>
    </div>
  );
}

const RadioButtonGroup = ({ wallpaperType, handleWallpaperType }) => {
  const options = [
    { value: "image", label: "Image" },
    { value: "video", label: "Video" },
    { value: "appLink", label: "App Link" },
  ];

  return (
    <>
      <p
        style={{ fontSize: "18px", marginBottom: "10px" }}
        className={styles.ConfigTitle + " label"}
      >
        Link Type
      </p>

      <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        {options.map((option) => (
          <label
            key={option.value}
            style={{
              display: "flex",
              gap: "5px",
              fontSize: "14px",
              color: "black",
            }}
          >
            <input
              type="radio"
              value={option.value}
              checked={wallpaperType === option.value}
              onChange={handleWallpaperType}
            />
            {option.label}
          </label>
        ))}
      </div>
    </>
  );
};
