import React from "react";
import styles from "../../../assets/styles/orgSettings.module.scss";

function PreviewIsNotAvailable({ wallpaperLink }) {
  return (
    <div
      className={styles.wallpapers}
      style={{
        height: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: "aliceblue"
      }}
    >
      <span style={{ fontSize: "13px" }}>Preview is not available</span>

      <a
        href={wallpaperLink}
        target="_blank"
        style={{
          fontSize: "13px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2px",
          textDecoration: "underline",
        }}
      >
        Open link in new tab
        <div style={{ width: "14px", marginTop: "4px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
            />
          </svg>
        </div>
      </a>
    </div>
  );
}

export default PreviewIsNotAvailable;
