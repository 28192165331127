import * as XLSX from "xlsx";
import moment from "moment";

function getDevices(deviceArray) {
  return deviceArray?.reduce((agg, value) => {
    agg[value.deviceType || value.type] = value.deviceName || value.name;
    return agg;
  }, {});
}

export function getDuration(startTime, endTime) {
  if (!startTime || !endTime) {
    return "";
  }

  var minsDuration = Math.ceil(
    moment.duration(endTime.diff(startTime)).asMinutes()
  );
  if (minsDuration < 0) {
    return 0;
  }
  if (!isNaN(minsDuration)) {
    return minsDuration;
  }
}

export const roomsList = [
  {
    label: "Room Name",
    field: "roomName",
  },
  {
    label: "Workspace",
    field: "workspace",
  },
  {
    label: "City",
    field: "city",
  },
  {
    label: "Size",
    field: "noOfSeats",
  },
  {
    label: "Camera",
    field: "Video Camera",
  },
  {
    label: "Microphone",
    getValue: (data) => data["Microphone"] || data["Microphones"],
  },
  {
    label: "Misc",
    getValue: (data) => {
      return data.deviceTypes
        .filter(
          (type) =>
            ["Video Camera", "Microphone", "Microphones"].indexOf(type) === -1
        )
        .filter((type) => !!data[type])
        .map((type) => `${data[type]} (${type})`)
        .join(",");
    },
  },
  {
    label: "Ving box Mac ID",
    field: "macId",
  },
  {
    label: "Version",
    field: "firmwareVersion",
  },
  {
    label: "Activated/Deactivated",
    field: "deviceActiveStatus",
  },
  {
    label: "Online/Offline",
    field: "roomOnlineStatus",
  },
];

export const meetingsList = [
  {
    label: "Name",
    field: "username",
  },
  {
    label: "Email ID",
    field: "email",
  },
  {
    label: "Room Name",
    field: "roomName",
  },
  {
    label: "Meeting Date",
    field: "meetingDate",
  },
  {
    label: "Meet Start Time",
    field: "meetingStartTime",
  },
  {
    label: "Meet End Time",
    field: "meetingEndtime",
  },
  {
    label: "Duration (in minutes)",
    field: "duration",
  },
  {
    label: "Meeting Type",
    field: "meetingType",
  },
];

export function exportToExcel(fileName, dataList, fieldData) {
  const data = [];
  const formatLabels = dataList.map((data) => data.label);
  data.push(formatLabels);
  fieldData?.forEach((dt) => {
    const devices = getDevices(dt.additionalDevice) || {};
    const updData = {
      ...dt,
      ...devices,
      roomName: dt?.roomId?.roomName || dt?.roomName,
      username:dt?.userId?.fullName || dt?.username,
      email: dt?.userId?.email || dt?.email,
      deviceTypes: Object.keys(devices),
      workspace: dt.orgLocId?.orgWorkSpaceName,
      city: dt.orgLocId?.addressId?.cityName,
      deviceActiveStatus: dt.deviceStatus ? "Activated" : "Deactivated",
      roomOnlineStatus: dt.onlineStatus === "Active" ? "Online" : "Offline",
      duration: getDuration(
        moment(dt.meetingStartTime, "HH:mm:ss a"),
        moment(dt.meetingEndtime, "HH:mm:ss a")
      ),
    };
    const newData = dataList.map((data) =>
      data.getValue ? data.getValue(updData) : updData[data.field]
    );
    data.push(newData);
  });
  const workbook = XLSX.utils.book_new();
  const sheet = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, sheet, fileName);
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
}
