import React from "react";
import styles from "../../../assets/styles/auth.module.scss";
import { InputText, CustomButton, CustomLink } from "../../../shared_ui_components";
import {  googleOAuthClientId, microsoftOAuthClientId } from "../../../constants";

const linkStyle = {
  socialLink : {
    border: "1px solid #DBDBDB",
    borderRadius: "8px",
    padding: "12px 24px",
    display: "flex",
    gap: "8px",
    width: "100%",
  }
}

function Divider() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        margin: "18px 0",
        color: "#DBDBDB",
      }}
    >
      <hr style={{ width: "100%", border: ".5px solid #DBDBDB" }} />
      <p style={{ color: "#4d5c68", fontSize: "12px" }}>Or</p>
      <hr style={{ width: "100%", border: ".5px solid #DBDBDB" }} />
    </div>
  );
}

export default function SignInForm(props) {
  const { formData, error, submitLoader } = props;
  const socialLoginLoader = false;
  var base_url = window.location.origin;

return (
    <div className={styles.signinFormWrapper}>
      
      <InputText
        label="Email ID *"
        placeholder="eg, johndoe@email.com"
        value={formData.email ? formData.email : ""}
        onChange={(e) => props.handleChange("email", e.target.value.trim())}
        error={error.email}
        className={error.email ? "errorGroup" : " "}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            props.handleSubmit();
          }
        }}
      />
      <div className={styles.passwordSection}>
        <InputText
          label="Password *"
          placeholder="********"
          type={formData.password_show ? "text" : "password"}
          autoComplete="current-password"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => props.handleChange("password", e.target.value)}
          value={formData.password}
          error={error.password}
          className={error.password ? "errorGroup" : " "}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              props.handleSubmit();
            }
          }}
        />
        <CustomLink
          to="/forgot-password"
          className={styles.forgotPasswordBlk + " label"}
          text="Forgot Password?"
        />
      </div>
      <CustomButton
        children={submitLoader ? "Signing In.." : "Sign In"}
        className={`primaryBtn submitBtn ${submitLoader ? "disabledBtn" : ""}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.handleSubmit();
        }}
      />
      <CustomButton
        children="Register As Partner"
        className="registerOutlinedBtn"
        onClick={()=>window.location.href="https://www.letsving.com/partner/registration"}
      />

      <div>
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "16px",
          }}
        >
          <a
            href={`https://accounts.google.com/o/oauth2/v2/auth?scope=email%20profile&include_granted_scopes=true&response_type=token&state=1CXFac0eAkqBarEbqCbABBUkFppA788811uX7jE&redirect_uri=${base_url}/signin&client_id=${googleOAuthClientId}`}
            style={linkStyle.socialLink}
          >
            <img
              alt="Google"
              src="https://letsving-web.s3.ap-south-1.amazonaws.com/letsving/assets/img/google_icon.svg"
              style={{ width: "20px", height: "20px" }}
            />
            <span>Sign in with Google</span>
          </a>

          <a
           href={`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${microsoftOAuthClientId}&redirect_uri=https%3A%2F%2Fmeet.letsving.com%2Fauth%2Flogin&response_mode=query&response_type=code&state=partners.letsving.com&scope=openid+profile`}
           style={linkStyle.socialLink}
          >
            <img
              alt="microsoft"
              src="/images/microsoft-icon.svg"
              style={{ width: "20px", height: "20px" }}
            />
            <span>Sign in with Microsoft</span>
          </a>
        </div>
      </div>
    </div>
  );
}
