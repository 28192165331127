import { styles } from "@material-ui/pickers/views/Calendar/Calendar";

export const imageBasePath = "https://letsving-web.s3.ap-south-1.amazonaws.com/letsving/assets/img/";

export const videoBasePath = "https://letsving-web.s3.ap-south-1.amazonaws.com/letsving/assets/video/";

export const googleOAuthClientId = "119323444287-huul655h5e3jncc8ns9bd3vejgior5f4.apps.googleusercontent.com";
export const microsoftOAuthClientId = "4fcade30-7144-4917-8a39-50c93930b9c6";
export const domainSuffix = process.env.REACT_APP_LETSVING_DOMAIN_SUFFIX || ".letsving.com";
export const enableWebsiteContent = process.env.REACT_APP_ENABLE_WEBSITE_CONTENT === "true";

export const regExpression = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  url: /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+([-]*)+[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  password: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).{8,}$/,
  digits: /^(0|[1-9][0-9]{0,9})$/,
  gst: /^([0][1-9]|[1-2][0-9]|[3][0-8])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/,
  pan: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
  pincode: /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/,
  phoneNumber: /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/,
  macAddress: /^[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}:[0-9a-fA-F]{2}$/,
  validDomainNameRegex : /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/
};

export const reactSelectStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,

      backgroundColor: isDisabled ? null : isSelected ? "#ECEFFD" : isFocused ? "#ECEFFD" : null,
      color: isDisabled ? "#ccc" : isSelected ? "#013440" : "#013440",
      cursor: isDisabled ? "not-allowed" : "pointer",
      "font-weight": "400",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#ECEFFD" : "#ECEFFD"),
      },
    };
  },
  control: (styles, { isFocused, isSelected, isDisabled }) => ({
    ...styles,
    background: isFocused ? "#ECEFFD" : "#FFFFFF",
    // boxShadow: isFocused ? '0 0 0 1px #17C3B2' : 'none',
    boxShadow: "none",
    //border: 'none',
    border: isFocused ? "1px solid #4361EE" : "1px solid #E6E8E9",
    ":hover": {
      background: isFocused ? "#ECEFFD" : "#FFFFFF",
    },
  }),

  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#E7F0F5",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#415766",
    fontSize: 14,
    fontWeight: 400,
    padding: "0px 10px 0px 10px",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#415766",
    ":hover": {
      backgroundColor: "#E7F0F5",
      color: "#415766",
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  valueContainer: (styles) => ({
    ...styles,
    minHeight: "48px",
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: 14,
    fontWeight: 400,
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: "0 8px",
    color: "#415766",
    // background: '#EEF1F6;',
    height: "48px",
    // borderRadius: '0px',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#99A2A9",
    fontSize: "14px",
    lineHeight: "21px",
  }),
};
export const reactSelectStylesSmall = {
  ...reactSelectStyles,
  control: (styles, { isFocused, isSelected, isDisabled }) => ({
    ...styles,
    background: isFocused ? "#ECEFFD" : "#FFFFFF",
    // boxShadow: isFocused ? '0 0 0 1px #17C3B2' : 'none',
    boxShadow: "none",
    border: "none",
    border: isFocused ? "1px solid #4361EE" : "none",
    ":hover": {
      background: isFocused ? "#ECEFFD" : "#FFFFFF",
    },
    minHeight: "32px",
    height: "32px",
  }),

  valueContainer: (styles) => ({
    ...styles,
    height: "32px",
    minHeight: "32px",
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: 14,
    fontWeight: 400,
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: "0 8px",
    color: "#415766",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#99A2A9",
    fontSize: "14px",
    lineHeight: "21px",
  }),
};

export const customMediumSelector = {
  ...reactSelectStyles,
  control: (styles, { isFocused, isSelected, isDisabled }) => ({
    ...styles,
    height: "20px !important",
    minHeight: "20px !important",
    background: isFocused ? "#E7F0F5" : "#ECEFFD",
    // boxShadow: isFocused ? '0 0 0 1px #17C3B2' : 'none',
    boxShadow: "none",
    // 'border': isFocused ? '1px solid #DEE2ED' : '1px solid #DEE2ED',
    ":hover": {
      // border: isFocused ? '1px solid #DEE2ED' : '1px solid #DEE2ED',
      background: isFocused ? "#E7F0F5" : "#FFFFFF",
    },
  }),
};

export const reactSelectStylesMediumSize = {
  ...reactSelectStyles,
  control: (styles, { isFocused, isSelected, isDisabled }) => ({
    ...styles,
    background: isFocused ? "#ECEFFD" : "#FFFFFF",
    // boxShadow: isFocused ? '0 0 0 1px #17C3B2' : 'none',
    boxShadow: "none",
    border: "none",
    border: isFocused ? "1px solid #4361EE" : "1px solid #E6E8E9",
    ":hover": {
      background: isFocused ? "#ECEFFD" : "#FFFFFF",
    },
    minHeight: "38px",
    height: "38px",
    width: "140px",
  }),

  valueContainer: (styles) => ({
    ...styles,
    height: "38px",
    minHeight: "38px",
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: 14,
    fontWeight: 400,
    top: "45%",
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: "0 8px",
    color: "#415766",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#99A2A9",
    fontSize: "14px",
    lineHeight: "38px",
  }),
};

export const reactSelectStylesLarge = {
  ...reactSelectStyles,
  control: (styles, { isFocused, isSelected, isDisabled }) => ({
    ...styles,
    background: isFocused ? "#ECEFFD" : "#FFFFFF",
    // boxShadow: isFocused ? '0 0 0 1px #17C3B2' : 'none',
    boxShadow: "none",
    border: "none",
    border: isFocused ? "1px solid #4361EE" : "1px solid #E6E8E9",
    ":hover": {
      background: isFocused ? "#ECEFFD" : "#FFFFFF",
    },
    minHeight: "48px",
    height: "48px",
  }),

  valueContainer: (styles) => ({
    ...styles,
    height: "48px",
    minHeight: "48px",
    overflow: "visible",
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: 14,
    fontWeight: 400,
    whiteSpace: "pre",
    top: "50%",
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: "0 8px",
    color: "#415766",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#99A2A9",
    fontSize: "14px",
  }),
  input: (styles) => ({
    ...styles,
    position: "relative",
    top: "-6px",
  }),
};
export const reactSelectStylesMultiSelect = {
  ...reactSelectStyles,
  control: (styles, { isFocused, isSelected, isDisabled }) => ({
    ...styles,
    background: isFocused ? "#ECEFFD" : "#FFFFFF",
    // boxShadow: isFocused ? '0 0 0 1px #17C3B2' : 'none',
    boxShadow: "none",
    border: "none",
    border: isFocused ? "1px solid #4361EE" : "1px solid #E6E8E9",
    ":hover": {
      background: isFocused ? "#E6E6E6" : "#FFFFFF",
    },
    minHeight: "48px",
  }),

  valueContainer: (styles) => ({
    ...styles,
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#E6E6E6",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#020712",
    fontSize: 14,
    fontWeight: 400,
    padding: "0px 10px 0px 10px",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#415766",
    ":hover": {
      backgroundColor: "#E7F0F5",
      color: "#415766",
    },
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: "0 8px",
    color: "#415766",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#99A2A9",
    fontSize: "14px",
    lineHeight: "38px",
  }),
};

export const errorCodes = {
  orgWorkSpaceName: {
    0: "",
    1: "Please enter Workspace name",
  },
  orgWorkSpaceNameObj: {
    requiredFlag: true,
  },
  pincode: {
    0: "",
    1: "Please enter Pincode",
    4: "Please enter valid Pincode",
  },
  pincodeObj: {
    requiredFlag: true,
    regexPattern: regExpression.pincode,
  },
  address: {
    0: "",
    1: "Please enter Registered Address",
  },
  addressObj: {
    requiredFlag: true,
  },
  email: {
    0: "",
    1: "Please enter Email address",
    4: "Please enter valid Email address",
  },
  emailObj: {
    requiredFlag: true,
    regexPattern: regExpression.email,
  },
  roomName: {
    0: "",
    1: "Please enter Room Name",
  },
  roomNameObj: {
    requiredFlag: true,
  },
  orgName: {
    0: "",
    1: "Please enter Name of the Organisation",
  },
  orgNameObj: {
    requiredFlag: true,
  },
  countryName: {
    0: "",
    1: "Please select Country",
  },
  countryNameObj: {
    requiredFlag: true,
  },
  cityName: {
    0: "",
    1: "Please select City",
  },
  cityNameObj: {
    requiredFlag: true,
  },
  stateName: {
    0: "",
    1: "Please select State",
  },
  stateNameObj: {
    requiredFlag: true,
  },
  contactNumber: {
    0: "",
    1: "Please enter contact number",
    4: "Please enter valid contact number",
  },
  contactNumberObj: {
    requiredFlag: true,
    regexPattern: regExpression.phoneNumber,
  },
  mobile: {
    0: "",
    1: "Please enter contact number",
    4: "Please enter valid contact number",
  },
  mobileObj: {
    requiredFlag: true,
    regexPattern: regExpression.phoneNumber,
  },
  panNumber: {
    0: "",
    1: "Please enter PAN number",
    4: "Please enter valid PAN number",
  },
  panNumberObj: {
    requiredFlag: true,
    regexPattern: regExpression.pan,
  },
  gstNumber: {
    0: "",
    1: "Please enter GST number",
    4: "Please enter valid GST number",
  },
  gstNumberObj: {
    requiredFlag: true,
    regexPattern: regExpression.gst,
  },
  tinNumber: {
    0: "",
    1: "Please enter TIN number",
  },
  tinNumberObj: {
    requiredFlag: true,
  },
  fullName: {
    0: "",
    1: "Please enter Full Name",
  },
  fullNameObj: {
    requiredFlag: true,
  },
  newDomain: {
    0: "",
    1: "Please enter Domain Name",
    4: "Please enter valid Domain Name",
  },
  newDomainObj: {
    requiredFlag: true,
    regexPattern: regExpression.url
  },
  password: {
    0: "",
    1: "Please enter Old Password",
  },
  passwordObj: {
    requiredFlag: true,
  },
  newPassword: {
    0: "",
    1: "Please enter New Password",
    4: "Enter a new password that is atleast 8 characters and different from your previously used passwords",
  },
  newPasswordObj: {
    requiredFlag: true,
    regexPattern: regExpression.password,
  },
  confirmPassword: {
    0: "",
    1: "Please enter Confirm Password",
  },
  confirmPasswordObj: {
    requiredFlag: true,
  },
  additionalDeviceName: {
    0: "",
    1: "Please enter Device name",
  },
  additionalDeviceNameObj: {
    requiredFlag: true,
  },
  additionalDeviceType: {
    0: "",
    1: "Please enter Device type",
  },
  additionalDeviceTypeObj: {
    requiredFlag: true,
  },
  roleId: {
    0: "",
    1: "Please select role",
  },
  roleIdObj: {
    requiredFlag: true,
  },
  workspaceId: {
    0: "",
    1: "Please select workspace",
  },
  workspaceIdObj: {
    requiredFlag: true,
  },
  poNumber: {
    0: "",
    1: "Please enter PO number",
  },
  poNumberObj: {
    requiredFlag: true,
  },
  File: {
    0: "",
    1: "Please upload PO",
  },
  FileObj: {
    requiredFlag: true,
  },
  selectRoom: {
    0: "",
    1: "Please select room name",
  },
  selectRoomObj: {
    requiredFlag: true,
  },
  macId: {
    0: "",
    1: "Please enter your macId",
    4: "Please enter valid macId",
  },
  macIdObj: {
    requiredFlag: true,
    regexPattern: regExpression.macAddress,
  },
  noOfSeats: {
    0: "",
    1: "Please enter Room Seating Capacity",
  },
  noOfSeatsObj: {
    requiredFlag: true,
  },
};
