import Reports from "./container/Reports";
import HomeLayoutHoc from "../../hoc/homeLayoutHoc";
import QuotationLayoutHoc from "../../hoc/quotationLayoutHoc";
import MeetingDetailsReports from "./container/MeetingDetailsReports";
import CurrentMeetings from "./container/CurrentMeetings";
export const reportsRoute = [
  {
    path: "/reports",
    component: HomeLayoutHoc(Reports),
    key: "reports",
  },
  {
    path: "/:id/reports",
    component: QuotationLayoutHoc(Reports),
    key: "reports-org",
  },
  {
    path: "/:id/current-meetings",
    component: QuotationLayoutHoc(CurrentMeetings),
    key: "current-meetings",
  },
  {
    path: "/:id/reports/meetingDetails/",
    component: QuotationLayoutHoc(MeetingDetailsReports),
    key: "reports-org-from-to",
  },
  {
    path: "/:id/reports/:duration",
    component: QuotationLayoutHoc(Reports),
    key: "reports-org-duration",
  },
];
