import React, { useState, useEffect } from "react";
import { imageBasePath } from "../../../constants";
import styles from "../../../assets/styles/workspaces.module.scss";
import { CustomLink, CustomButton, InputText, NoResult } from "../../../shared_ui_components";
import { Grid, Dialog } from "@material-ui/core";
import LanguageIcon from '@material-ui/icons/Language';
import { getWorkspaceWallpaperApi } from "../../workspaces/apiServices";
import { useParams } from "react-router";
import Shimmer from "../../workspaces/components/Shimmer";
import { addDeviceWallpaper } from "../apiServices";
import { toastFlashMessage } from "../../../utils";
import PreviewIsNotAvailable from "../../settings/components/PreviewIsNotAvailable";

function CustomImage({ item, activeImg, handleSelectImage }) {
  const wallpaperLink = item.img;
  const link = new URL(`${wallpaperLink}`);
  const localLink = link.protocol === "http:";

  if (localLink) {
    const activeStyle = "2px solid #4361ee";
    const normalStyle = "1px solid #e6e8e9";

    const active = activeImg === item.img ? activeStyle : normalStyle;

    return (
      <div
        style={{
          height: "100%",
          cursor: "pointer",
          border: `${active}`,
          borderRadius: "8px",
          marginTop: "10px",
        }}
        className={`${styles.wallpapers} ${
          activeImg === item.img ? styles.active : null
        }`}
        onClick={() =>
          handleSelectImage(
            item.img,
            item._id,
            item?.wallpaperType,
            item?.wallpaperName
          )
        }
      >
        <PreviewIsNotAvailable wallpaperLink={wallpaperLink} />
      </div>
    );
  }
  return (
    <img
      className={`${styles.wallpapers} ${
        activeImg === item.img ? styles.active : null
      }`}
      src={item.img}
      alt=""
      onClick={() =>
        handleSelectImage(
          item.img,
          item._id,
          item?.wallpaperType,
          item?.wallpaperName
        )
      }
    />
  );
}

function CustomVideo({ item, activeImg, handleSelectImage }) {
  const wallpaperLink = item.img;
  const link = new URL(`${wallpaperLink}`);
  const localLink = link.protocol === "http:";

  if (localLink) {
    const activeStyle = "2px solid #4361ee";
    const normalStyle = "1px solid #e6e8e9";

    const active = activeImg === item.img ? activeStyle : normalStyle;

    return (
      <div
        style={{
          height: "100%",
          cursor: "pointer",
          border: `${active}`,
          borderRadius: "8px",
          marginTop: "10px",
          padding : "6px"
        }}
        onClick={() =>
          handleSelectImage(
            item.img,
            item._id,
            item?.wallpaperType,
            item?.wallpaperName
          )
        }
      >
        <PreviewIsNotAvailable wallpaperLink={wallpaperLink} />
      </div>
    );
  }
  return (
    <video
      src={item.img}
      onClick={() =>
        handleSelectImage(
          item.img,
          item._id,
          item?.wallpaperType,
          item?.wallpaperName
        )
      }
      className={`${styles.wallpapers} ${
        activeImg === item.img ? styles.active : null
      }`}
      alt=""
      autoPlay
      muted
      loop
    />
  );
}




export const Wallpapers = (props) => {
  const { deviceId, closeDialog, handleDeviceDetail } = props;
  const { id } = useParams();

  const [activeImg, setActiveImg] = useState(null);
  const [wallpaperType, setWallpaperType] = useState(null);
  const [wallpaperName, setWallpaperName] = useState(null);
  // const [selectedImgId, setSelectedImgId] = useState(null);
  const [wallpapers, setWallpapers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noWallPaperStatus, setNoWallPaperStatus] = useState(false);
  const [validateLoader, setValidateLoader] = useState();
  const [error, setError] = useState(false);

  const handleClose = () => {
    setActiveImg(null);
    setWallpaperType(null)
    setWallpaperName(null)
    // setSelectedImgId(null);
    closeDialog(false);
  };

  const getAllWallpapers = () => {
    getWorkspaceWallpaperApi({ orgRefId: id }).then((response) => {
      if (response.status && response.data.wallpapers.length > 0) {
        setWallpapers(response.data.wallpapers);
        setLoading(false);
      } else {
        setLoading(false);
        setNoWallPaperStatus(true);
      }
    });
  };

  useEffect(() => {
    if (props.open) {
      getAllWallpapers();
    } else {
    }
  }, [props.open]);

  const handleSave = () => {
    setValidateLoader(true);
    if (activeImg == null) {
      setValidateLoader(false);
      setError(true);
    } else {
      addDeviceWallpaper({ deviceId: deviceId, imageUrl: activeImg ,wallpaperType,wallpaperName}).then((response) => {
        if (response.status) {
          //console.log(response, "response");
          handleClose();
          setValidateLoader(false);
          handleDeviceDetail();
        } else {
          setValidateLoader(false);
          toastFlashMessage("Something went wrong while adding wallpaper", "error");
        }
      });
    }
  };

  const handleSelectImage = (img, imgId,wallpaperType,wallpaperName) => {
    console.log("handleSelectImage calling:img:",img)
    setError(false);
    setActiveImg(img);
    setWallpaperType(wallpaperType)
    setWallpaperName(wallpaperName)
    //setSelectedImgId(imgId);
  };

  return (
    <>
      <div className={styles.setWallpaperWrapper}>
        <div className={styles.addWallpaperTitleWrapper}>
          <div className={styles.addWallpaperTitle}>
            <img src={imageBasePath + "set_wallpaper.svg"} alt="" />
            <p className={styles.addWallpaperHeader + " subheading"}>Set device wallpaper</p>
          </div>
          <div className={styles.closeDialog}>
            <img onClick={handleClose} src={imageBasePath + "user_cancel.svg"} alt="" />
          </div>
        </div>
        <div className={styles.addWallpaperContentWrapper}>
          <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
            {loading ? (
              <Shimmer type="wallpapers" />
            ) : noWallPaperStatus ? (
              <div style={{ marginTop: "-100px" }}>
                <NoResult
                  image="no_workspace_room.png"
                  title="No Wallpapers found"
                  subtext="Looks like you have no wallpapers. Start importing wallpapers"
                  btnText="Go to Settings"
                  link={`/${id}/settings`}
                />
              </div>
            ) : (
              wallpapers?.map((item, key) => (
               <Grid key={key} item md={6}>
                   {item.wallpaperType!=="appLink"?<div className={styles.wallpapersList} style={{position:"relative"}}>
                   <DisplayWallpaperName wallpaperName={item.wallpaperName}/>
                    {item.img?.includes(".mp4") ? (
                      <CustomVideo item={item} activeImg={activeImg} handleSelectImage={handleSelectImage}/>
                    ):(
                      <CustomImage item={item} activeImg={activeImg} handleSelectImage={handleSelectImage} />
                    )}
                  </div>:
                  <div className={`${styles.wallpapersList} ${styles.appLinkWallpaper}`} 
                  style={{border:`${activeImg===item.img?"2px solid #4361ee":"1px solid #e6e8e9"}`,height:"117px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",position:"relative"}} 
                  onClick={()=> handleSelectImage(item.img,item._id,item?.wallpaperType,item?.wallpaperName)}>
                 <DisplayWallpaperName wallpaperName={item.wallpaperName}/>
                    <div className={styles.appLinkIconContainer}>
                      <LanguageIcon />
                      <p>Application Link</p>
                    </div>
                    
                    <p style={{border:"0px",overflow:"hidden",maxHeight:"50px"}} className={`${styles.wallpapers} ${styles.appLinkText} ${activeImg===item.img ? styles.active : null }`}>{item.img.replace("https://", "")}</p>
                  </div>}
                </Grid>
              ))
            )}
          </Grid>
        </div>
        {error ? (
          <h6 className="errorMsg" style={{ textAlign: "center", position: "relative", bottom: "30px" }}>
            Please select device wallpaper
          </h6>
        ) : null}
        {!noWallPaperStatus && (
          <div className={styles.setWallpaperActionButton}>
            <CustomLink
              className={`${styles.saveButton} primaryBtn ${validateLoader ? "disabledBtn" : ""}`}
              text={validateLoader ? "Saving..." : "Save"}
              onClick={() => handleSave()}
            />
          </div>
        )}
      </div>
    </>
  );
};

const DisplayWallpaperName=({wallpaperName})=>{
  return(
    <p
    style={{
      position: "absolute",
      left: "30%",
      top: "-20px",
      zIndex: "999",
      fontSize: "14px",
      textTransform: "capitalize",
      textOverflow:"ellipsis",
    }}
  >
    {wallpaperName}
  </p>
  )
}