import React, { useRef, useState } from "react";
import styles from "../../../assets/styles/orgSettings.module.scss";
import trash from "../../../assets/images/trash.png";
import playImg from "../../../assets/images/play.png";

import LanguageIcon from "@material-ui/icons/Language";
import PreviewIsNotAvailable from "./PreviewIsNotAvailable";

function CustomImage({ wallpaperLink, wallpaper }) {
  const link = new URL(`${wallpaperLink}`);
  const localLink = link.protocol === "http:";

  if (localLink) {
    return (
      <div style={{ height: "110%", marginTop: "-20px" }}>
        <PreviewIsNotAvailable wallpaperLink={wallpaperLink} />
      </div>
    );
  }

  return (
    <img
      className={
        styles.wallpapers +
        " " +
        `${wallpaperLink === wallpaper ? styles.active : " "}`
      }
      src={wallpaperLink}
      alt=""
      checked={wallpaperLink === wallpaper ? true : false}
    />
  );
}

function CustomVideo({ wallpaperLink, wallpaper, handleVideoLoaded, videoRef }) {
  const link = new URL(`${wallpaperLink}`);
  const localLink = link.protocol === "http:";

  if (localLink) {
    return (
      <div style={{ height: "110%", marginTop: "-20px" }}>
        <PreviewIsNotAvailable wallpaperLink={wallpaperLink} />
      </div>
    );
  }

  return (
  <video
    src={wallpaperLink}
    preload="none"
    ref={videoRef}
    muted
    loop
    className={
      styles.wallpapers +
      " " +
      `${wallpaperLink === wallpaper ? styles.active : " "}`
    }
    onLoadedData={handleVideoLoaded}
  ></video>
  );
}



export const WallpaperImages = (props) => {
  const { img, _id, wallpaper, wallpaperType, wallpaperName } = props;

  const [isHovered, setHover] = useState(false);

  const videoRef = useRef(null);

  const [isVideoPlaying, setVideoPlaying] = useState(false);

  const [isVideoLoaded, setVideoLoaded] = useState(false);

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play().then(() => {
        setVideoPlaying(true);
      });
    }
  };

  const handleVideoLoaded = () => {
    setVideoLoaded(true);
  };

  const handleDelete = () => {
    props.handleDeleteWallpaper(_id);
  };

  const handleSetDefaultWallpaper = () => {
    props.defaultWallpapers(_id);
  };

  if(!img) {
    // console.log("!img:",img)
    return null
  }
  
  const isVideo = img?.includes(".mp4");
  const link = new URL(`${img}`);
  const localLink = link.protocol === "http:";

  return (
    <>
      {
        <div
          className={styles.wallpapersList}
          style={{ position: "relative", marginBottom: "10%" }}
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <p
            style={{
              position: "absolute",
              left: "30%",
              top: "-20px",
              zIndex: "999",
              fontSize: "14px",
              textTransform: "capitalize",
              textAlign:"center"
            }}
          >
            {wallpaperName}
          </p>
          {wallpaperType !== "appLink" ? (
            !isVideo ? (
              <CustomImage wallpaper={wallpaper} wallpaperLink={img}/>
            ) : (
              <div style={{ position: "relative", height : "100%" }}>
                <CustomVideo wallpaper={wallpaper} wallpaperLink={img} handleVideoLoaded={handleVideoLoaded} videoRef={videoRef}/>
                {!localLink && !isVideoLoaded && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "aliceblue",
                    }}
                  >
                    <img
                      src={playImg}
                      style={{
                        padding: "10px",
                        color: "#fff",
                        cursor: "pointer",
                        width: "50px",
                        height: "50px",
                      }}
                    />
                    <p
                      style={{
                        margin: 0,
                        fontSize: "14px",
                        fontWeight: "bold",
                        cursor: "pointer",
                        fontWeight: 500,
                        color: "#4361ee",
                      }}
                    >
                      Hover Over Me
                    </p>
                  </div>
                )}
              </div>
            )
          ) : (
            <div
              className={`${styles.wallpapersList} ${styles.appLinkWallpaper}`}
              style={{
                border: `${
                  wallpaper === img ? "2px solid #4361ee" : "1px solid #e6e8e9"
                }`,
                height: "117px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className={styles.appLinkIconContainer}>
                <LanguageIcon />
                <p>Application Link</p>
              </div>
              <p
                style={{ border: "0px", overflow: "hidden", maxHeight: "50px" }}
                className={`${styles.wallpapers} ${styles.appLinkText} ${
                  img === wallpaper ? styles.active : null
                }`}
                checked={img === wallpaper ? true : false}
              >
                {img.replace("https://", "")}
              </p>
            </div>
          )}

          {isHovered && (
            <>
              {img === wallpaper ? (
                <button className={styles.currentBtn}>Current wallpaper</button>
              ) : (
                <div>
                  {
                    !localLink ?<button
                    className={styles.defaultBtn}
                    style={{
                      top: `${
                        wallpaperType === "appLink" ||
                        (isVideo && !isVideoPlaying)
                          ? "20%"
                          : "50%"
                      }`,
                    }}
                    onClick={handleSetDefaultWallpaper}
                  >
                    Set as default
                  </button> : null
                  }
                  
                  {!localLink &&isVideo && !isVideoPlaying && (
                    <button
                      className={styles.defaultBtn}
                      style={{ top: "60%", textAlign: "center" }}
                      onClick={handlePlayVideo}
                    >
                      Play Video
                    </button>
                  )}
                  {wallpaperType === "appLink" && (
                    <a
                      style={{ top: "60%", textAlign: "center" }}
                      className={styles.defaultBtn}
                      href={img}
                      target="_blank"
                    >
                      View App Link
                    </a>
                  )}
                </div>
              )}
              <div
                className={styles.deleteBtn}
                style={{
                  top: `${
                    wallpaperType === "appLink" || (isVideo && !isVideoPlaying)
                      ? "10px"
                      : "18px"
                  }`,
                  right: `${
                    wallpaperType === "appLink" || (isVideo && !isVideoPlaying)
                      ? "8px"
                      : "14px"
                  }`,
                }}
              >
                <img
                  src={trash}
                  alt=""
                  style={{ width: "20px", height: "20px" }}
                  onClick={handleDelete}
                />
              </div>
            </>
          )}
        </div>
      }
    </>
  );
};
