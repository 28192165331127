import { useEffect } from "react"; 
import axios from "axios";
import { socialSignUpApi } from "../ApiService";
import { toastFlashMessage } from "../../../utils";

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL =
  process.env.REACT_APP_HTTP_BASE_URL || "https://api.letsving.com/api";

function handleMicrosoftSignIn(code) {
  socialSignUpApi({
    msAuthCode: code,
    redirectUri: "https://meet.letsving.com/auth/login",
    source: "Web",
  })
    .then(response => {
      if (response.status) {
        localStorage.setItem("userInfo", JSON.stringify(response));
        window.location.href = "/";
      } else {
        toastFlashMessage(response.message, "error");
      }
    })
    .catch(error => {
      console.log("error:", error);
    });
}


function AuthSignIn() {
  useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    const code = url.get("code");

    if(code){
      handleMicrosoftSignIn(code);
    }else {
      window.location.href = "/signin";
    }
    
  }, []);

  return null;
}

export default AuthSignIn;
