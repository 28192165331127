import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import styles from "../../../assets/styles/workspaces.module.scss";
import { globalPostService } from "../../../utils/globalApiServices";
import { imageBasePath } from "../../../constants";
import { CustomLink } from "../../../shared_ui_components";

function getMeetingDuration(meetingDateTimeISOString) {
  const meetingDateTime = new Date(meetingDateTimeISOString);
  const currentDateTime = new Date();

  const timeDifference = currentDateTime.getTime() - meetingDateTime.getTime();

  const millisecondsInHour = 1000 * 60 * 60;
  const millisecondsInMinute = 1000 * 60;

  const hoursDifference = Math.floor(timeDifference / millisecondsInHour);
  const minutesDifference = Math.floor(
    (timeDifference % millisecondsInHour) / millisecondsInMinute
  );

  // Ensure that hours and minutes are not negative
  const hours = Math.max(0, hoursDifference);
  const minutes = Math.max(0, minutesDifference);

  const timeDuration = `${hours} hrs, ${minutes} mins`;

  return timeDuration;
}

function handleCurrentMeetingsApi(orgId, setCurrentMeetings, fromDate) {
  setCurrentMeetings({ loading: true });
  const url = `/complete-meeting-data-v1`;
  const payload = {
    orgId: orgId,
    fromDate: fromDate,
    toDate: "",
    workspaceId: "",
    roomId: "",
    currentMeetings: true,
  };

  return new Promise(() => {
    globalPostService(url, payload)
      .then((response) => {
        setCurrentMeetings({
          loading: false,
          success: true,
          error: false,
          mesage: "",
          data: response.data || [],
        });
      })
      .catch((err) => {
        setCurrentMeetings({
          loading: false,
          success: false,
          error: true,
          message: err.message,
        });
      });
  });
}

function sortByName(apiData, asc) {
  return apiData.slice().sort((a, b) => {
    if (asc) {
      return a.userId.fullName.localeCompare(b.userId.fullName);
    } else {
      return b.userId.fullName.localeCompare(a.userId.fullName);
    }
  });
}

function CurrentMeetings() {
  const history = useHistory();
  const { id } = useParams();
  const orgId = id;
  const currentDate = new Date();
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);
  const fromDate = yesterday.toISOString();
  const [currentMeetings, setCurrentMeetings] = useState({
    loading: false,
    success: false,
    error: false,
    data: [],
    message: "",
  });

  useEffect(() => {
    const fetchData = () => {
      handleCurrentMeetingsApi(orgId, setCurrentMeetings, fromDate);
    };
    fetchData();
    const interval = setInterval(fetchData, 60000);

    return () => clearInterval(interval);
  }, [orgId]);

  const handleGoBack = () => {
    history.goBack();
  };

  if (currentMeetings.loading) {
    return "Loading...";
  }

  return (
    <div className={styles.workspacesPage}>
      <div className={styles.workspacesWrapper}>
        <div className={styles.backBtnWrapper}></div>
        <div className={styles.workspacesHeading} style={{ marginTop: "10px" }}>
          <div style={{ marginLeft: "20px" }}>
            <CustomLink
              onClick={handleGoBack}
              className={" customLink" + " paragraph"}
              text="Back"
              leftIcon={imageBasePath + "arrow_left_icon.svg"}
            />
          </div>
          <h2 className={styles.workspacesTitle + " subheading "}>
            Current Meetings
          </h2>
        </div>
        <CurrentMeetingList
          apiData={currentMeetings?.data || []}
          setCurrentMeetings={setCurrentMeetings}
          currentMeetings={currentMeetings}
        />
      </div>
    </div>
  );
}

export default CurrentMeetings;

function CurrentMeetingList({ apiData, setCurrentMeetings, currentMeetings }) {
  const [sortByNameAsc, setSortByNameAsc] = useState(true);
  const handleSortByName = () => {
    const sortedData = sortByName(apiData, sortByNameAsc);
    setCurrentMeetings({
      ...currentMeetings,
      data: sortedData,
    });
    setSortByNameAsc(!sortByNameAsc);
  };
  return (
    <Grid container>
      <Grid item md={12}>
        <div className={styles.workspacesList}>
          <div
            className={styles.workspacesListInnerBlk}
            style={{ marginTop: "20px" }}
          >
            <div className={styles.workspacesListTableTitle}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item md={3}>
                      <div className={styles.workspacesTableTitleTextWrapper}>
                        <p
                          className={
                            styles.workspacesTableTitleText + " paragraph "
                          }
                        >
                          Name
                        </p>
                        <button type="button" onClick={handleSortByName}>
                          <img
                            src={imageBasePath + "up_arrow.svg"}
                            alt=""
                            className="sortUp"
                          />
                          <img
                            src={imageBasePath + "down_arrow.svg"}
                            alt=""
                            className="sortDown"
                          />
                        </button>
                      </div>
                    </Grid>
                    <Grid item md={3}>
                      <div className={styles.workspacesTableTitleTextWrapper}>
                        <p
                          className={
                            styles.workspacesTableTitleText + " paragraph "
                          }
                        >
                          Room Name
                        </p>
                      </div>
                    </Grid>
                    <Grid item md={2}>
                      <div className={styles.workspacesTableTitleTextWrapper}>
                        <p
                          className={
                            styles.workspacesTableTitleText + " paragraph "
                          }
                        >
                          Meeting Type
                        </p>
                      </div>
                    </Grid>
                    <Grid item md={2}>
                      <div className={styles.workspacesTableTitleTextWrapper}>
                        <p
                          className={
                            styles.workspacesTableTitleText + " paragraph "
                          }
                        >
                          Meeting Date
                        </p>
                      </div>
                    </Grid>
                    <Grid item md={2}>
                      <div className={styles.workspacesTableTitleTextWrapper}>
                        <p
                          className={
                            styles.workspacesTableTitleText + " paragraph "
                          }
                        >
                          Meeting Time
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            {apiData.length > 0 ? (
              <>
                {apiData.map((meeting) => {
                  const meetingDuration = getMeetingDuration(
                    meeting?.createdAt
                  );
                  return (
                    <div key={meeting._id}>
                      <CurrentMeetingDetails
                        meeting={meeting}
                        meetingDuration={meetingDuration}
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "50px",
                  alignItems: "center",
                  fontWeight: 600,
                }}
              >
                There are no meetings scheduled during this time interval.
              </div>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

function CurrentMeetingDetails({ meeting, meetingDuration }) {
  return (
    <Grid
      container
      spacing={2}
      style={{
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      <Grid
        item
        md={12}
        style={{
          borderBottom: "1px solid #e6e8e9",
          marginLeft: "9px",
          marginRight: "9px",
        }}
      >
        <Grid
          container
          direction="row"
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
          style={{ height: "50px" }}
        >
          <Grid item md={3}>
            <div
              className={styles.workspaceContentWrapper}
              style={{ paddingLeft: "10px" }}
            >
              <div className={styles.workspaceDetailWrapper}>
                <p className={styles?.workspaceName + " paragraphSemiBold "}>
                  {meeting?.userId?.fullName}
                </p>
                <p style={{ fontSize: "12px" }}>{meeting?.userId?.email}</p>
              </div>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className={styles.workspaceContentWrapper}>
              <p className={styles.workspaceRoom + " paragraph "}>
                {meeting?.roomName}
              </p>
            </div>
          </Grid>
          <Grid item md={2}>
            <div className={styles.workspaceContentWrapper}>
              <p className={styles.workspaceRoom + " paragraph "}>
                {meeting?.meetingType}
              </p>
            </div>
          </Grid>
          <Grid item md={2}>
            <div className={styles.workspaceContentWrapper}>
              <p className={styles.workspaceRoom + " paragraph "}>
                {meeting?.meetingDate}
              </p>
            </div>
          </Grid>
          <Grid item md={2}>
            <div className={styles.workspaceContentWrapper}>
              <p className={styles.workspaceRoom + " paragraph "}>
                {meetingDuration}
              </p>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
