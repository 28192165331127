import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import { Grid } from "@material-ui/core";
import { getMeetingsDetailsfromParams } from "../apiServices";
import { toastFlashMessage } from "../../../utils";
import styles from "../../../assets/styles/users.module.scss";
import { imageBasePath } from "../../../constants";
import Shimmer from "../../users/components/Shimmer";
import { exportToExcel, getDuration, meetingsList } from "../exportsData";
import { CustomLink } from "../../../shared_ui_components";

const header = [
  {
    label: "Full Name",
    width: 3
  },
  {
    label: "Room Name"
  },
  {
    label: "Meeting Type"
  },
  {
    label: "Meeting Date",
    width: 3
  },
  {
    label: "Duration (in Minutes)"
  }
];

// Function convert from UTC to local date-time
function convertToLocalTime(meetingDate, time) {
  return moment.utc(`${meetingDate} ${time}`, "DD/MM/YYYY hh:mm:ss a").local();
}

function convertMeetingDetailsData(reports) {
  const data = [];
  if (reports.length > 0) {
    reports?.forEach((dt) => {
      const { meetingType } = dt;

      const meetingDate = dt.meetingDate;

      let startTime = null;
      if (dt.meetingStartTime) {
        startTime = convertToLocalTime(meetingDate, dt.meetingStartTime);
      }

      let endTime = null;
      if (dt.meetingEndtime) {
        endTime = convertToLocalTime(meetingDate, dt.meetingEndtime);
      }

      const newData = {
        username: dt?.userId?.fullName,
        email: dt?.userId?.email,
        roomName: dt?.roomId?.roomName,
        meetingDate,
        meetingStartTime: startTime ? startTime.format("hh:mm:ss a") : null,
        meetingEndtime: endTime ? endTime.format("hh:mm:ss a") : null,
        meetingType,
        duration: getDuration(startTime, endTime),
      };

      data.push(newData);
    });
    return data;
  }
}



function getUrlParams() {
  const url = new URL(window.location.href);
  const fromDate = url.searchParams.get("from");
  const toDate = url.searchParams.get("to");

  const fromDateTimeStamp = fromDate ? new Date(fromDate) : null;
  const displayFromDate = fromDateTimeStamp?.toLocaleDateString();
  const toDateTimeStamp =  toDate ? new Date(toDate) : null;
  const displayToDate = toDateTimeStamp?.toLocaleDateString();

  return { fromDate, toDate, displayFromDate, displayToDate };
}

function MeetingDetailsReports() {
  const { id } = useParams();
  const [params] = useState(getUrlParams);

  const [apiState, setApiState] = useState({
    loading: true,
    error: false,
    data: []
  });

  const reports = apiState.data;

  useEffect(() => {
    getMeetingsDetailsfromParams({
      orgId: id,
      fromDate: params.fromDate || "",
      toDate: params.toDate || ""
    }).then(response => {
      if (response.status) {
        setApiState({
          loading: false,
          data: convertMeetingDetailsData(response.data)
        });
        toastFlashMessage("Reports fetched successfully", "success");
      } else {
        setApiState({
          error: true,
          data: []
        });
        toastFlashMessage("Something went wrong! Please try again.", "error");
      }
    });
  }, [params.fromDate, params.toDate]);

  return (
    <div className={styles.usersPage}>
      <div className={styles.usersListWrapper}>
        <div
          className={styles.backBtnWrapper}
          style={{ marginLeft: "20px", marginTop: "20px" }}
        >
          <CustomLink
            className="backBtn"
            text="Back"
            leftIcon={imageBasePath + "arrow_left_icon.svg"}
            to={`/${id}/reports`}
          />
        </div>
        <div
          className={styles.usersHeading}
          style={{
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <h2
            className={styles.userTitle + " subheading "}
            style={{ display: "flex", flexDirection: "column" }}
          >
            MeetingDetails
            <span
              style={{ fontSize: "12px", color: "#67737d", fontWeight: "400" }}
            >
              {params.fromDate && params.toDate
                ? `From ${params.displayFromDate} - To ${params.displayToDate}`
                : "Till Date"}
            </span>
          </h2>
          <div style={{ marginLeft: "10px" }}>
            <button
              className={`primaryBtn`}
              onClick={() =>
                exportToExcel("List-of-meetings", meetingsList, reports)
              }
              style={{ cursor: "pointer", marginRight: "20px" }}
            >
              Download Report
            </button>
          </div>
        </div>
        <Grid container>
          <Grid item md={12}>
            <div className={styles.usersList}>
              <div className={styles.usersListInnerBlk}>
                {/* table title start */}
                <div className={styles.usersListTableTitle}>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        style={{ textAlign: "center" }}
                      >
                        {header.map((header, index) => {
                          return (
                            <Grid
                              item
                              md={header.width || 2}
                              style={{
                                border: "0px solid red",
                                textAlign: "center"
                              }}
                            >
                              <div
                                className={styles.usersTableTitleTextWrapper}
                              >
                                <p
                                  className={
                                    styles.usersTableTitleText + " paragraph "
                                  }
                                >
                                  {header.label}
                                </p>
                                <img
                                  src={imageBasePath + "up_arrow.svg"}
                                  alt=""
                                  className="sortUp"
                                />
                                <img
                                  src={imageBasePath + "down_arrow.svg"}
                                  alt=""
                                  className="sortDown"
                                />
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                {/* table title end */}
                {/* table content start */}
                <div className={styles.usersListTableContent}>
                  {apiState.loading ? (
                    <Shimmer type="orgUsersLists" />
                  ) : !reports ? (
                    <div>
                      <h2
                        style={{
                          color: "#011627",
                          fontSize: "14px",
                          lineHeight: "21px",
                          textAlign: "center",
                          padding: "20px 0px 20px 0px"
                        }}
                      >
                        There are no meetings scheduled during this time
                        interval.
                      </h2>
                    </div>
                  ) : (
                    reports &&
                    reports.map((row, index) => (
                      <MeetingContent {...row} key={index} />
                    ))
                  )}
                </div>
                {/* table content end */}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const MeetingContent = ({
  username,
  email,
  roomName,
  meetingDate,
  meetingStartTime,
  meetingEndtime,
  duration,
  meetingType
}) => {
  
  const userTimeZoneOffset = moment().utcOffset();

function convertToUserTimeZone(time) {
  const convertedTime = time.clone().utcOffset(userTimeZoneOffset);
  return convertedTime.isValid() ? convertedTime.format('hh:mm:ss a') : '';
}

const startTimeMoment = moment(meetingStartTime, 'hh:mm:ss a');
const endTimeMoment = moment(meetingEndtime, 'hh:mm:ss a');
const convertedStartTime = convertToUserTimeZone(startTimeMoment);
const convertedEndTime = convertToUserTimeZone(endTimeMoment);


  
  
  return (
    <div className={styles.userListItem}>
      <div className={styles.usersListTableRow}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Grid
              container
              direction="row"
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item md={3} style={{ border: "0px solid red" }}>
                <div className={styles.userContentWrapper}>
                  <div
                    className={styles.userDetailWrapper}
                    style={{ paddingLeft: "0px" }}
                  >
                    <p className={styles.userName + " paragraphSemiBold "}>
                      {username}
                    </p>
                    <p className={styles.userEmail + " paragraphSemiBold "}>
                      {email}
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid item md={2} style={{ border: "0px solid red" }}>
                <div className={styles.userContentWrapper}>
                  <p className={styles.lastActive + " paragraph "}>
                    {roomName}
                  </p>
                </div>
              </Grid>

              <Grid item md={2} style={{ border: "0px solid red" }}>
                <div className={styles.userContentWrapper}>
                  <p className={styles.lastActive + " paragraph "}>
                    {meetingType}
                  </p>
                </div>
              </Grid>

              <Grid item md={3} style={{ border: "0px solid red" }}>
                <div className={styles.userContentWrapper}>
                  <div
                    className={styles.userDetailWrapper}
                    style={{ paddingLeft: "0px" }}
                  >
                    <p className={styles.userName + " paragraphSemiBold "}>
                      {meetingDate}
                    </p>
                    <p className={styles.userEmail + " paragraphSemiBold "}>
                      {convertedStartTime} - {convertedEndTime}
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid item md={2} style={{ border: "0px solid red" }}>
                <div className={styles.userContentWrapper}>
                  <p className={styles.lastActive + " paragraph "}>
                    {duration ? duration : "-"}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default MeetingDetailsReports;
