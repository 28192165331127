import SignIn from "./container/SignIn";
import ForgotPassword from "./container/ForgotPassword";
import ResetPassword from "./container/ResetPassword";
import VerifiedEmailSuccessfully from "./container/VerifiedEmailSuccessfully";
import NetworkCheck from "../home/container/NetworkCheck";
import AuthSignIn from "./container/AuthSignIn";
export const authRoutes = [
    {
        path: "/signin",
        component: SignIn,
        key: "signin",
    },
    {
        path: "/networkcheck",
        component: NetworkCheck,
        key: "networkcheck",
    },
    {
        path: "/forgot-password",
        component: ForgotPassword,
        key: "forgotpassword",
    },
    {
        path: "/reset-password",
        component: ResetPassword,
        key: "resetpassword",
    },
    {
        path: "/VerifiedEmailSuccessfully",
        component: VerifiedEmailSuccessfully,
        key: "VerifiedEmailSuccessfully",
    },
    {
        path: "/auth/login",
        component: AuthSignIn,
        key: "AuthSignIn",
    },
];
